import React from "react"
import LayoutBasic from "../../components/LayoutBasic"
import BasicText from "../../components/BasicText"
import Info2column from "../../components/Info2column"
import CommonButton from "../../components/CommonButton"
import Seo from "../../components/Seo"
import Map01 from "../../images/interview_map_01_ja.svg"
import Map02 from "../../images/interview_map_02_ja.svg"
import Map03 from "../../images/interview_map_03_ja.svg"
import FlagMm from "../../images/flag_mm.svg"
import FlagJp from "../../images/flag_jp.svg"
import FlagId from "../../images/fkag_id.svg"
import FlagVn from "../../images/flag_vn.svg"

import css from "./stylesheets/interview.module.scss"
import CenterContentLayout from "../../components/CenterContentLayout"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default ({ data }) => {
  return (
    <div className={css.InterviewTop}>
      <Seo title={`INTERVIEW`} />
      <LayoutBasic lang={`ja`} style={{ marginBottom: `0` }}>
        <CenterContentLayout>
          <BasicText>
            <h1>INTERVIEW</h1>
            <p>
              日本で働いている先輩たちへのインタビューです。先輩・後輩、同僚、夫婦などさまざまな関係のふたりに話を聞きました。また、働いている施設と、暮らしている家を見せてもらいました。
            </p>
            <Info2column id={`interview-01`} noBorder={true}>
              <div className={`only-pc`}>
                <Link to={`/ja/interview/1`}>
                  <Img fluid={data.img01.childImageSharp.fluid} />
                </Link>
              </div>
              <div>
                <hr />
                <p className={css.date}>{`2020.3.25`}</p>
                <h3>わからないことを教えあいながら働く</h3>

                <div className={`only-sp`}>
                  <Link to={`/ja/interview/1`}>
                    <Img
                      fluid={data.img01.childImageSharp.fluid}
                      className={css.spOnlyImg}
                    />
                  </Link>
                </div>

                <p>
                  山崎僚恵
                  <small>さん（23歳）</small>× ジンジンモー
                  <small>さん（27歳）</small>
                </p>
                <div className={css.col2Styles}>
                  <div className={css.col2StylesLeft}>
                    <dl>
                      <dt>出身地</dt>
                      <dd>
                        <img src={FlagJp} alt="日本国旗" className={`flag`} />
                        日本 ×{" "}
                        <img
                          src={FlagMm}
                          alt="ミャンマー国旗"
                          className={`flag`}
                        />
                        ミャンマー
                      </dd>

                      <dt>ふたりの関係</dt>
                      <dd>同じ年に働き出した同僚</dd>
                    </dl>
                    <div className={css.commonButton + ` only-pc`}>
                      <CommonButton to={`/ja/interview/1`} align={`left`}>
                        MORE
                      </CommonButton>
                    </div>
                  </div>
                  <div className={css.col2StylesRight}>
                    <img
                      style={{ width: `100%` }}
                      src={Map01}
                      alt="北海道札幌市"
                      width={392}
                      height={418}
                      className={css.mapImage}
                    />
                  </div>
                </div>
                <div className={`only-sp`}>
                  <CommonButton to={`/ja/interview/1`}>MORE</CommonButton>
                </div>
              </div>
            </Info2column>

            <Info2column id={`interview-02`} noBorder={true}>
              <div className={`only-pc`}>
                <Link to={`/ja/interview/2`}>
                  <Img fluid={data.img02.childImageSharp.fluid} />
                </Link>
              </div>
              <div>
                <hr />
                <p className={css.date}>{`2020.3.25`}</p>
                <h3>自分の心と体を大切にしながら働く</h3>

                <div className={`only-sp`}>
                  <Link to={`/ja/interview/2`}>
                    <Img
                      fluid={data.img02.childImageSharp.fluid}
                      className={css.spOnlyImg}
                    />
                  </Link>
                </div>

                <p>
                  斉藤健一
                  <small>さん（39歳）</small>× トゥイー
                  <small>さん（26歳）</small>
                </p>
                <div className={css.col2Styles}>
                  <div className={css.col2StylesLeft}>
                    <dl>
                      <dt>出身地</dt>
                      <dd>
                        <img src={FlagJp} alt="日本国旗" className={`flag`} />
                        日本 ×{" "}
                        <img
                          src={FlagVn}
                          alt="ベトナム国旗"
                          className={`flag`}
                        />
                        ベトナム
                      </dd>

                      <dt>ふたりの関係</dt>
                      <dd>上司と部下</dd>
                    </dl>
                    <div className={css.commonButton + ` only-pc`}>
                      <CommonButton to={`/ja/interview/2`} align={`left`}>
                        MORE
                      </CommonButton>
                    </div>
                  </div>
                  <div className={css.col2StylesRight}>
                    <img
                      style={{ width: `100%` }}
                      src={Map02}
                      alt="長野県塩尻市"
                      width={392}
                      height={418}
                      className={css.mapImage}
                    />
                  </div>
                </div>
                <div className={`only-sp`}>
                  <CommonButton to={`/ja/interview/2`}>MORE</CommonButton>
                </div>
              </div>
            </Info2column>

            <Info2column id={`interview-03`} noBorder={true}>
              <div className={`only-pc`}>
                <Link to={`/ja/interview/3`}>
                  <Img fluid={data.img03.childImageSharp.fluid} />
                </Link>
              </div>
              <div>
                <hr />
                <p className={css.date}>{`2020.3.25`}</p>
                <h3>働く場所で、子どもも育つ</h3>

                <div className={`only-sp`}>
                  <Link to={`/ja/interview/3`}>
                    <Img
                      fluid={data.img03.childImageSharp.fluid}
                      className={css.spOnlyImg}
                    />
                  </Link>
                </div>

                <p>
                  リア
                  <small>さん（33歳）</small>× ウチュ
                  <small>さん（33歳）</small>
                </p>
                <div className={css.col2Styles}>
                  <div className={css.col2StylesLeft}>
                    <dl>
                      <dt>出身地</dt>
                      <dd>
                        <img
                          src={FlagId}
                          alt="インドネシア国旗"
                          className={`flag`}
                        />
                        インドネシア ×{" "}
                        <img
                          src={FlagId}
                          alt="インドネシア国旗"
                          className={`flag`}
                        />
                        インドネシア
                      </dd>

                      <dt>ふたりの関係</dt>
                      <dd>夫婦、同僚</dd>
                    </dl>
                    <div className={css.commonButton + ` only-pc`}>
                      <CommonButton to={`/ja/interview/3`} align={`left`}>
                        MORE
                      </CommonButton>
                    </div>
                  </div>
                  <div className={css.col2StylesRight}>
                    <img
                      style={{ width: `100%` }}
                      src={Map03}
                      alt="千葉県成田市"
                      width={392}
                      height={418}
                      className={css.mapImage}
                    />
                  </div>
                </div>
                <div className={`only-sp`}>
                  <CommonButton to={`/ja/interview/3`}>MORE</CommonButton>
                </div>
              </div>
            </Info2column>
          </BasicText>
        </CenterContentLayout>
      </LayoutBasic>
    </div>
  )
}
export const query = graphql`
  query {
    img01: file(relativePath: { eq: "interview/01/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img02: file(relativePath: { eq: "interview/02/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img03: file(relativePath: { eq: "interview/03/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
